header {
  background-color: var(--dark-blue);
  height: 7rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-text {
  font-size: 4rem;
  font-weight: 300;
  color: #fff;
}

.logo-text span {
  font-weight: 600;
  color: orangered;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  li a {
    color: #fff;
    font-size: 1.6rem;
    margin-right: 1rem;
  }
}
